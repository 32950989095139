import { urls } from "@shared/constants";
import { appendUtmParams, getUtmParams } from "@shared/tracking";

import { TCall, TCallInfo, TSubmissionStatus } from "__generated__/types";

export function getCallCardLink(
  call: Pick<
    TCall,
    | "id"
    | "submissionEndDate"
    | "submissionStartDate"
    | "website"
    | "slug"
    | "useNewSubmissionWizard"
  > & {
    callInfo?: Pick<TCallInfo, "id">;
  },
  submissionStatus?: TSubmissionStatus,
): string {
  const { submissionEndDate } = call;
  const end = new Date(submissionEndDate).valueOf();
  const now = Date.now();

  // only checking if contest is open, so now < end
  if (now < end) {
    if (submissionStatus === TSubmissionStatus.Draft) {
      return appendUtmParams(
        call.useNewSubmissionWizard
          ? urls.startSimplifiedSubmission(call.slug)
          : urls.startSubmission(call.id),
        getUtmParams(),
      );
    }
    // quick info exists
    if (call.callInfo) {
      return `?quickInfo=${call.id}`;
    }
    // submission finished
    if (submissionStatus === TSubmissionStatus.Submitted) {
      return appendUtmParams(urls.viewSubmission(call.id), getUtmParams());
    }
    if (call.website) {
      return call.website;
    }
    // if nothing found, return open call link as contest is still open
    return appendUtmParams(
      call.useNewSubmissionWizard
        ? urls.startSimplifiedSubmission(call.slug)
        : urls.startSubmission(call.id),
      getUtmParams(),
    );
  }
  // quick info exists
  if (call.callInfo) {
    return `?quickInfo=${call.id}`;
  }
  // submission finished
  if (submissionStatus === TSubmissionStatus.Submitted) {
    return appendUtmParams(urls.viewSubmission(call.id), getUtmParams());
  }
  if (call.website) {
    return call.website;
  }
  return null;
}
