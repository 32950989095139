import classNames from "clsx";
import { useTranslations } from "next-intl";

import { LegalLinks } from "@shared/components";
import { FooterLink, Container, Text } from "@shared/elements";

import { MESSAGE_PREFIX } from "src/constants";

type FooterProps = {
  className?: string;
};

export default function Footer({ className, ...rest }: FooterProps) {
  const t = useTranslations(`${MESSAGE_PREFIX}components.Footer`);
  return (
    <Container as="footer" className={classNames("py-12", className)} {...rest}>
      <nav>
        <ol className="flex flex-col items-center sm:flex-row sm:flex-wrap sm:justify-center space-y-4 sm:space-y-0 sm:space-x-10">
          <FooterLink href="https://www.picter.com/contest-hosting">
            {t("hostYourContest")}
          </FooterLink>
          <FooterLink href="https://www.picter.com/media">
            {t("advertise")}
          </FooterLink>
          <FooterLink href="https://support.picter.com/en/collections/1438231-submit-to-photo-contests">
            {t("knowledgeBase")}
          </FooterLink>
          <FooterLink href="https://www.picter.com/about">
            {t("aboutUs")}
          </FooterLink>
        </ol>
      </nav>
      <Text as="p" color="custom" className="text-center mt-8 text-gray-400">
        {t("madeByPicter")}
        <br />
        <LegalLinks />
      </Text>
    </Container>
  );
}

Footer.messages = [
  `${MESSAGE_PREFIX}components.Footer`,
  ...LegalLinks.messages,
];
