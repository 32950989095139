export default function DefaultOpenGraphTags() {
  return (
    <>
      <meta
        name="description"
        key="description"
        content="Picter is the number one destination to find & apply for creative visual contests"
      />
      <meta property="og:title" content="Picter Contests" key="og-title" />
      <meta
        property="og:description"
        key="og-description"
        content="Picter is the number one destination to find & apply for creative visual contests"
      />
    </>
  );
}
