import classNames from "clsx";
import { useTranslations } from "next-intl";
import { useMemo } from "react";
import { HiCreditCard } from "react-icons/hi";

import { Deadline, StartDate, StatusBadge } from "@shared/components";
import { Text } from "@shared/elements";
import { useSubmissionStatus } from "@shared/hooks";
import { toQueryString } from "@shared/utils";

import {
  TCallCardFields,
  TSubmissionCategory,
  TSubmissionStatus,
} from "__generated__/types";

import { MESSAGE_PREFIX } from "src/constants";

type CallCardProps = {
  className?: string;
  call: TCallCardFields;
  submissionStatus?: TSubmissionStatus | "LOADING";
};

export default function CallCard({
  className,
  call,
  submissionStatus,
  ...rest
}: CallCardProps) {
  const t = useTranslations(`${MESSAGE_PREFIX}components.CallCard`);
  const paymentMessage = useMemo(() => {
    if (call.customFeeDescriptionShort) {
      return call.customFeeDescriptionShort;
    }
    if (call.isFeeVoluntary) {
      return t("payment.voluntary");
    }
    if (
      call.categories.some(
        (category: TSubmissionCategory) =>
          category?.price + category?.imagePrice > 0,
      )
    ) {
      return t("payment.variable");
    }
    if (call.fee > 0) {
      return t("payment.fixed", {
        amount: call.fee,
        currency: call.currency,
      });
    }
    return t("payment.free");
  }, [call, t]);
  const status = useSubmissionStatus(
    call.submissionStartDate,
    call.submissionEndDate,
    submissionStatus,
  );
  const imgUrl = `https://${process.env.NEXT_PUBLIC_IMGIX_URL}${
    new URL(call.contestListTeaser).pathname
  }`;

  return (
    <div className={classNames("h-full flex flex-col", className)} {...rest}>
      <div>
        <div className="block aspect-w-4 aspect-h-3 w-full overflow-hidden mb-4 shadow-md group">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            srcSet={`${imgUrl}?${toQueryString({
              w: 400,
              dpr: 1,
            })} 1x, ${imgUrl}?${toQueryString({
              w: 400,
              dpr: 2,
            })} 2x, ${imgUrl}?${toQueryString({
              w: 400,
              dpr: 3,
            })} 3x,`}
            src={call.contestListTeaser}
            className="object-cover group-hover:brightness-110 group-hover:transition-colors group-hover:ease-out duration-100"
            alt={call.title.en}
          />
        </div>
        <div className="flex items-center justify-between mb-3">
          <StatusBadge status={status} />
          <div className="flex items-center justify-between">
            <Text
              size="sm"
              color="muted"
              weight="medium"
              className="flex group items-center"
            >
              <HiCreditCard className="mr-1" size="1.15rem" />
              {paymentMessage}
            </Text>
          </div>
        </div>
        <Text as="h2" size="lg" weight="medium" className="mb-2">
          {call.title.en}
        </Text>
        {status === "UPCOMING" ? (
          <StartDate date={new Date(call.submissionStartDate)} />
        ) : (
          <Deadline date={new Date(call.submissionEndDate)} />
        )}
      </div>
    </div>
  );
}

CallCard.messages = [
  `${MESSAGE_PREFIX}components.CallCard`,
  ...StatusBadge.messages,
  ...Deadline.messages,
  ...StartDate.messages,
];
