export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const paths = {
  index: "/",
  home: "/home",
  login: "/login",
  signup: "/signup",
  passwordReset: "/password-reset",
  passwordRecovery: "/password-recovery",
};

export const MESSAGE_PREFIX = "apps.main.";
