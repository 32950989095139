/**
 * Extracts the search parameters from a URL or path.
 *
 * @param url - The URL from which to extract the search parameters.
 * @returns The URLSearchParams object containing the extracted search parameters.
 */
export function getSearchParams(url: string): URLSearchParams;

/**
 * Extracts the search parameters from a path.
 *
 * @param path - The path from which to extract the search parameters.
 * @returns The URLSearchParams object containing the extracted search parameters.
 */
export function getSearchParams(path: string): URLSearchParams;

export function getSearchParams(urlOrPath: string): URLSearchParams {
  // ^ = matches the beginning of the string
  // [^?]+ = matches any character that is not a question mark one or more times
  // (\?|$) = matches a question mark or the end of the string
  // eg. https://example.com?param=value -> ?param=value
  // eg. /signup?email=example@email.com -> ?email=example@email.com
  return new URLSearchParams(urlOrPath.replace(/^[^?]+(\?|$)/i, "?"));
}
